@import url('https://fonts.googleapis.com/css?family=Plus+Jakarta+Sans:300,400,500,600,700');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

@layer components {
    .center-flex {
        @apply flex items-center justify-center;
    }

    .clickable {
        @apply cursor-pointer transition hover:opacity-70;
    }
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

.hidden-scroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-track {
    background: #eee;
}

::-webkit-scrollbar-thumb {
    background: #d8d8d8;
}

::-webkit-scrollbar-thumb:hover {
    background: #d8d8d8;
}

* {
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    padding: 0;
}

body,
input,
select,
textarea {
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.loader {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.FIE_root span,
.FIE_root a,
.FIE_root p,
.FIE_root h1,
.FIE_root h2,
.FIE_root h3,
.FIE_root h4,
.FIE_root h5,
.FIE_root h6,
.FIE_root li,
.FIE_root ul,
.FIE_root ol,
.FIE_root blockquote,
.FIE_root pre,
.FIE_root code,
.FIE_root table,
.FIE_root tr,
.FIE_root td,
.FIE_root th,
.FIE_root input,
.FIE_root textarea,
.FIE_root select,
.FIE_root button,
.FIE_root label {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.FIE_topbar-save-button,
.FIE_topbar {
    display: none !important;
}



/* GOOGLE MAP */
.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
}

.google-map {
    width: 100%;
    height: 60vh;
}

.pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
}

.pin-icon {
    font-size: 4rem;
}

.pin-text {
    font-size: 1.3em;
}

@media screen and (min-width: 799px) {
    .google-map {
        height: 80vh;
    }

    .map-h2 {
        font-size: 1.3rem;
        font-weight: 400;
    }

    .pin {
        width: 15vw;
    }

    .pin-icon {
        font-size: 10vw;
    }
}

.attachment-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
}

.download-overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
}

.dark-overlay {
    background: rgba(0, 0, 0, 0.1);
}
